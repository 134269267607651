import React from 'react';
import dayjs from 'dayjs';
import { FORMAT_DATE_TIME_II } from 'constants';
import { LIST_ROLES } from 'constants/auth.constant';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { UserStatus } from 'components/common/status';

export const userColumns = [
  {
    accessor: 'name',
    Header: 'Name',
    width: 220,
    headerProps: { className: 'pe-1 text-900' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, avatar } = rowData.row.original;
      return (
        <Flex alignItems="center">
          {avatar ? (
            <Avatar src={avatar} size="xl" className="me-2" />
          ) : (
            <Avatar size="xl" name={name} className="me-2" />
          )}
          <div className="flex-1">
            <h5 className="mb-0 fs--1">{name}</h5>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    width: 250,
    headerProps: { className: 'text-900' },
    Cell: rowData => {
      const { email } = rowData.row.original;
      return <a href={`mailto:${email}`}>{email}</a>;
    }
  },
  {
    accessor: 'contact_number',
    Header: 'Phone',
    headerProps: { className: 'text-900' },
    Cell: rowData => {
      const { contact_number: phone } = rowData.row.original;
      return <a href={`tel:${phone}`}>{phone}</a>;
    }
  },
  {
    accessor: 'roles',
    Header: 'Permissions',
    width: 120,
    headerProps: { className: 'text-900' },
    Cell: rowData => {
      const { roles } = rowData.row.original;
      const listRoles = LIST_ROLES.filter(item => (roles || []).includes(item.value)).map(
        item => item.label
      );
      return <>{listRoles.join(', ')}</>;
    }
  },
  {
    accessor: 'updated_at',
    Header: 'Updated At',
    width: 120,
    headerProps: { className: 'text-900' },
    Cell: rowData => {
      const { updated_at } = rowData.row.original;
      return <span>{dayjs(updated_at).format(FORMAT_DATE_TIME_II)}</span>;
    }
  },
  {
    accessor: 'created_at',
    Header: 'Create At',
    width: 120,
    headerProps: { className: 'text-900' },
    Cell: rowData => {
      const { created_at } = rowData.row.original;
      return <span>{dayjs(created_at).format(FORMAT_DATE_TIME_II)}</span>;
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    headerProps: { className: 'text-900' },
    width: 100,
    Cell: rowData => {
      const { status } = rowData.row.original;
      return <UserStatus status={status} />;
    }
  }
];
