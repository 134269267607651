import { axiosClient } from './request';

const fleetServices = {
  create(body) {
    return axiosClient.post('/fleets', body);
  },
  list({
    pageSize = 20,
    pageIndex = 0,
    sort_by = [],
    globalFilter = '',
    dates = [null, null],
    moreFilterData = {}
  } = {}) {
    return axiosClient.get('/fleets', {
      params: {
        sort_by,
        limit: pageSize,
        page: pageIndex + 1,
        search: globalFilter,
        start_date: dates[0],
        end_date: dates[1],
        ...moreFilterData
      }
    });
  },
  detail(id) {
    return axiosClient.get(`/fleets/${id}`);
  },
  updateFleet(id, body) {
    return axiosClient.put(`fleets/${id}`, body);
  },
  deleteFleetSupervisor(userId, supervisorId) {
    return axiosClient.delete(`fleets/${userId}/users/${supervisorId}`);
  },
  getFleetSupervisors(id) {
    return axiosClient.get(`/fleets/${id}/supervisors`);
  },
  getListAvailableToSupervisor(id, params) {
    return axiosClient.get(`/fleets/${id}/assign-users`, { params });
  },
  assignToFleetSupervisor(id, uid) {
    return axiosClient.post(`fleets/${id}/users/${uid}`);
  },
  connectStripe(fleetId) {
    return axiosClient.post(`fleets/${fleetId}/invite`);
  }
};

export default fleetServices;
