import React from 'react';
import dayjs from 'dayjs';
import { FORMAT_DATE_TIME_II } from 'constants/common.constant';
import StripeStatus from './common/StripeStatus';
import CurrencyAmount from './common/CurrencyAmount';
import PayOutStatus from './common/PayOutStatus';
import { ROLES } from 'constants/index';

export const NOT_AVAILABLE = 'N/A';

// if user is not admin/cabfare/group, can only view some stats if their endpointType is equal their role.
export const authByEndpointType = ({ endpointType, viewAsRole, value }) => {
  if ([ROLES.ADMIN, ROLES.CABFARE_INTERNAL, ROLES.GROUP].includes(viewAsRole)) return value;

  const endpointTypeToUpper = endpointType.toUpperCase() || NOT_AVAILABLE;

  if (ROLES[endpointTypeToUpper] === viewAsRole) return value;
  return NOT_AVAILABLE;
};

export const visibleColumnForAdmin = [
  'transaction_time',
  'stripe_currency',
  'amount',
  'stripe_status',
  'receipt_number',
  'stripe_card_brand',
  'stripe_fee',
  'stripe_payment_network',
  'parent_commission_rate',
  'driver_name',
  'payment_point_2_payto',
  'payment_point_2_name',
  'payment_point_2_stripe_payout_date',
  'payment_point_1_group_stripe_payout_date'
];

export const availableColumnsForAdmin = [
  'transaction_time',
  'stripe_currency',
  'amount',
  'stripe_status',
  'receipt_number',
  'stripe_card_brand',
  'stripe_fee',
  'stripe_payment_network',
  'parent_commission_rate',
  'group_name',
  'company_name',
  'driver_name',
  'state',
  'country',
  'payment_point_2_payto',
  'payment_point_2_name',
  'payment_point_2_rate',
  'payment_point_2_stripe_payout_date',
  'payment_point_1_group_rate',
  'payment_point_1_group_stripe_payout_date'
];

export const availableColumnsForCabfareIntenal = [
  'transaction_time',
  'stripe_currency',
  'amount',
  'stripe_status',
  'receipt_number',
  'stripe_card_brand',
  'group_name',
  'company_name',
  'driver_name',
  'state',
  'country',
  'payment_point_2_payto',
  'payment_point_2_name',
  'payment_point_2_rate',
  'payment_point_2_stripe_payout_date',
  'payment_point_1_group_rate',
  'payment_point_1_group_stripe_payout_date'
];

export const visibleColumnForCabfareIntenal = [
  'transaction_time',
  'stripe_currency',
  'amount',
  'stripe_status',
  'receipt_number',
  'stripe_card_brand',
  'driver_name',
  'payment_point_2_payto',
  'payment_point_2_name',
  'payment_point_2_stripe_payout_date',
  'payment_point_1_group_stripe_payout_date'
];

export const visibleColumnForDriver = [
  'transaction_time',
  'stripe_currency',
  'amount',
  'stripe_status',
  'receipt_number',
  'driver_name',
  'payment_point_2_amount',
  'payment_point_2_stripe_payout_status',
  'payment_point_2_stripe_payout_date'
];

export const availableColumnsForDriver = [
  'transaction_time',
  'stripe_currency',
  'amount',
  'stripe_status',
  'receipt_number',
  'stripe_card_brand', //
  'pick_up_location', //
  'drop_off_location', //
  'driver_name',
  'vehicle_plate_number',
  'payment_point_2_payto',
  'payment_point_2_name',
  'payment_point_2_rate',
  'payment_point_2_amount',
  'payment_point_2_stripe_payout_status',
  'payment_point_2_stripe_payout_date'
];

// All columns
export const columnsConfig = [
  {
    accessor: 'transaction_time',
    Header: 'Date/Time',
    headerProps: { className: 'pe-3 text-900' },
    cellProps: {
      className: 'py-2 px-2'
    },
    Cell: rowData => {
      const { transaction_time } = rowData.row.original;
      return <>{dayjs(transaction_time).format(FORMAT_DATE_TIME_II)}</>;
    }
  },
  {
    accessor: 'stripe_currency',
    Header: 'Currency',
    width: 70,
    headerProps: { className: 'text-start text-900' },
    cellProps: {
      className: 'justify-content-start text-uppercase'
    }
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    width: 80,
    headerProps: { className: 'text-end text-900' },
    cellProps: {
      className: 'justify-content-end'
    },
    Cell: rowData => {
      const { amount } = rowData.row.original;
      return <CurrencyAmount amount={amount} />;
    }
  },
  {
    accessor: 'stripe_status',
    Header: 'Status',
    width: 150,
    headerProps: {
      className: 'text-center text-900'
    },
    cellProps: {
      className: 'fs-0 justify-content-center'
    },
    Cell: rowData => {
      const { transaction_status } = rowData.row.original;
      return <StripeStatus status={transaction_status} minWidth="135px" />;
    }
  },
  {
    accessor: 'receipt_number',
    Header: 'Receipt #',
    width: 100,
    headerProps: {
      className: 'text-center text-900'
    },
    cellProps: {
      className: 'justify-content-center'
    }
  },
  {
    accessor: 'stripe_card_brand',
    Header: 'Card Type',
    width: 120,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2 text-capitalize'
    }
  },
  {
    accessor: 'pick_up_location',
    Header: 'Pick Up',
    width: 200,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2 text-capitalize'
    }
  },
  {
    accessor: 'drop_off_location',
    Header: 'Drop off',
    width: 200,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2 text-capitalize'
    }
  },
  {
    accessor: 'stripe_fee',
    Header: 'Stripe Fee',
    headerProps: {
      className: 'text-end pe-2 text-900'
    },
    width: 80,
    cellProps: {
      className: 'justify-content-end py-2 px-2'
    },
    Cell: rowData => {
      const { stripe_fee } = rowData.row.original;
      return <CurrencyAmount amount={stripe_fee} />;
    }
  },
  {
    accessor: 'stripe_payment_network',
    Header: 'Network',
    width: 100,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'justify-content-start py-2 px-2'
    },
    Cell: rowData => {
      const { stripe_network } = rowData.row.original;
      return <span>{stripe_network}</span>;
    }
  },
  {
    accessor: 'parent_commission_rate',
    Header: 'Parent Effective Rate',
    headerProps: {
      className: 'text-end pe-2 text-900'
    },
    cellProps: {
      className: 'justify-content-end py-2 px-2'
    },
    Cell: rowData => {
      const { parent_commission_rate: parentCommissionRate } = rowData.row.original;
      return <span>{`${parentCommissionRate || 0}%`}</span>;
    }
  },
  {
    accessor: 'group_name',
    Header: 'Group',
    width: 180,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start  py-2 px-2'
    }
  },
  {
    accessor: 'company_name',
    Header: 'Company',
    width: 180,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start  py-2 px-2'
    }
  },
  {
    accessor: 'driver_name',
    Header: 'Driver Name',
    width: 180,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2'
    }
  },
  {
    accessor: 'state',
    Header: 'State',
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2'
    }
  },
  {
    accessor: 'country',
    Header: 'Country',
    width: 100,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start  py-2 px-2'
    }
  },
  {
    accessor: 'payment_point_2_payto',
    Header: 'Endpoint Type',
    width: 110,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start  py-2 px-2'
    }
  },
  {
    accessor: 'payment_point_2_name',
    Header: 'Endpoint Name',
    width: 180,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2'
    }
  },
  {
    accessor: 'payment_point_2_rate',
    Header: 'Endpoint Rate',
    width: 110,
    headerProps: {
      className: 'text-end pe-2 text-900'
    },
    cellProps: {
      className: 'justify-content-end py-2 px-2'
    },
    Cell: rowData => {
      const { payment_point_2_rate, payment_point_2_payto, viewAsRole } = rowData.row.original;
      const endpointRate = authByEndpointType({
        viewAsRole,
        value: payment_point_2_rate,
        endpointType: payment_point_2_payto
      });

      if (endpointRate == NOT_AVAILABLE) return null;
      return <span>{endpointRate || 0} %</span>;
    }
  },
  {
    accessor: 'payment_point_2_amount',
    Header: 'Endpoint Amount (Inc Tax)',
    width: 200,
    headerProps: {
      className: 'text-end pe-3 text-900'
    },
    cellProps: {
      className: 'text-end  py-2 px-2'
    },
    Cell: rowData => {
      const { payment_point_2_amount, payment_point_2_payto, viewAsRole } = rowData.row.original;
      const endpointAmount = authByEndpointType({
        viewAsRole,
        value: payment_point_2_amount,
        endpointType: payment_point_2_payto
      });

      if (endpointAmount == NOT_AVAILABLE) return null;
      return <CurrencyAmount amount={endpointAmount} />;
    }
  },
  {
    accessor: 'payment_point_2_stripe_payout_status',
    Header: 'Endpoint Payout Status',
    width: 200,
    headerProps: {
      className: 'text-start pe-3 text-900'
    },
    cellProps: {
      className: 'text-center py-2 px-2'
    },
    Cell: rowData => {
      const { payment_point_2_stripe_payout_status, payment_point_2_payto, viewAsRole } =
        rowData.row.original;

      const endpointPayOutStatus = authByEndpointType({
        viewAsRole,
        endpointType: payment_point_2_payto,
        value: payment_point_2_stripe_payout_status
      });

      if (endpointPayOutStatus == NOT_AVAILABLE) return null;
      return <PayOutStatus status={endpointPayOutStatus} />;
    }
  },
  {
    accessor: 'payment_point_2_stripe_payout_date',
    Header: 'Endpoint Payout Date',
    width: 160,
    headerProps: {
      className: 'text-start pe-2 text-900'
    },
    cellProps: {
      className: 'text-start py-2 px-2'
    },
    Cell: rowData => {
      const {
        viewAsRole,
        payment_point_2_payto,
        payment_point_2_stripe_payout_date: item
      } = rowData.row.original;
      if (!item) return '';
      const endpointPayOutDate = authByEndpointType({
        viewAsRole,
        value: item,
        endpointType: payment_point_2_payto
      });
      if (endpointPayOutDate == NOT_AVAILABLE) return null;
      return <>{item ? dayjs.unix(endpointPayOutDate).format(FORMAT_DATE_TIME_II) : ''}</>;
    }
  },
  {
    accessor: 'payment_point_1_group_rate',
    Header: 'Group Rate',
    width: 100,
    headerProps: {
      className: 'text-end pe-3 text-900'
    },
    cellProps: {
      className: 'justify-content-end py-2 px-2'
    },
    Cell: rowData => {
      const {
        viewAsRole,
        payment_point_2_payto,
        payment_point_1_group_rate: groupRate
      } = rowData.row.original;
      const ppGroupRate = authByEndpointType({
        viewAsRole,
        value: groupRate,
        endpointType: payment_point_2_payto
      });
      if (ppGroupRate == NOT_AVAILABLE) return null;
      return <>{ppGroupRate}</>;
    }
  },
  {
    accessor: 'payment_point_1_group_stripe_payout_date',
    Header: 'Group Payout Date',
    headerProps: {
      className: 'text-start pe-3 text-900'
    },
    cellProps: {
      className: 'py-2 px-2'
    },
    Cell: rowData => {
      const {
        viewAsRole,
        payment_point_2_payto,
        payment_point_1_group_stripe_payout_date: payoutDate
      } = rowData.row.original;
      const ppStripePayoutDate = authByEndpointType({
        viewAsRole,
        value: payoutDate,
        endpointType: payment_point_2_payto
      });
      if (ppStripePayoutDate == NOT_AVAILABLE || !payoutDate) return null;
      return <>{dayjs.unix(payoutDate).format(FORMAT_DATE_TIME_II)}</>;
    }
  }
];
