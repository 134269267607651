import React, { useEffect, useMemo, useState } from 'react';
import { faEdit, faCheck, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFlexLayout
} from 'react-table';
import Flex from '../Flex';
import ActionButton from '../ActionButton';
import { EditableCell, IndeterminateCheckbox } from './AdvanceTableUtils';
import { FIXED_RANGE_TIME } from '../CFDatePicker/config';

const AdvanceTableWrapper = ({
  totalCount,
  data,
  columns,
  sortable,
  children,
  fetchData,
  hasDatePicker,
  selection,
  loading,
  selectionColumnWidth,
  rowsPerPageSelection,
  onUpdateRow,
  onChangePage,
  defaultVisibleColumnIds,
  onChangeGlobalFilter,
  initialDatePicker,
  initialRangeTime,
  editableRow = false,
  perPage = 10,
  siblingCount = 1,
  defaultSort = [],
  manualSortBy = true,
  visibleColumnIds = [],
  canEditTableRow = true
}) => {
  const hiddenColumns = visibleColumnIds.length
    ? columns.filter(item => !visibleColumnIds.includes(item.accessor)).map(d => d.accessor)
    : columns;
  const [activeFilter, setIsActiveFilter] = useState(false);
  const [moreFilterData, setMoreFilterData] = useState({});
  const [dates, setDatePicker] = useState(initialDatePicker || [null, null]);
  const [rangeTime, setRangeTime] = useState(initialRangeTime || FIXED_RANGE_TIME);
  const [pageIndex, setPageIndex] = useState(0);

  const onSelectDatePicker = (updatedDates, range) => {
    if (updatedDates.every(item => item === null) && dates.every(item => item === null)) return;
    setRangeTime(range);
    setDatePicker(updatedDates);
  };

  const [dataTable, setDataTable] = useState([]);
  const [originalData, setOriginalData] = useState(() => []);

  useEffect(() => {
    setDataTable(data || []);
    setOriginalData(data || []);
  }, [data]);

  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const updateMyData = (rowIndex, columnId, value) => {
    setDataTable(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const revertData = (rowIndex, revert = true) => {
    if (revert) {
      setDataTable(old =>
        old.map((row, index) => (index === rowIndex ? originalData[rowIndex] : row))
      );
    } else {
      setOriginalData(old => old.map((row, index) => (index === rowIndex ? data[rowIndex] : row)));
    }
  };

  const setFilterStatus = status => {
    setIsActiveFilter(status);
    if (!status) {
      setMoreFilterData({});
    }
  };

  const onSubmitFilter = moreFilterData => {
    setMoreFilterData(moreFilterData);
  };

  const {
    page,
    pageCount,
    prepareRow,
    setPageSize,
    headerGroups,
    getTableProps,
    canPreviousPage,
    setGlobalFilter,
    setHiddenColumns,
    state: { pageSize, selectedRowIds, globalFilter, sortBy }
  } = useTable(
    {
      columns,
      data: dataTable,
      defaultColumn: {
        Cell: EditableCell
      },
      updateMyData,
      revertData,
      editableRowIndex,
      setEditableRowIndex,
      disableSortBy: !sortable,
      manualSortBy,
      autoResetSortBy: false,
      autoResetPage: false,
      autoResetExpanded: false,
      pageCount: Math.ceil(totalCount / perPage),
      manualGlobalFilter: true,
      manualPagination: true,
      manualFilters: true,
      initialState: {
        hiddenColumns,
        sortBy: defaultSort,
        pageSize: perPage,
        pageIndex
      }
    },
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            headerProps: {
              style: {
                width: selectionColumnWidth
              }
            },
            cellProps: {
              style: {
                width: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ]);
      }
      if (editableRow && canEditTableRow) {
        hooks.visibleColumns.push(columns => [
          ...columns,
          {
            accessor: 'action',
            id: 'action',
            Header: 'Action',
            headerProps: { className: 'text-900' },
            Cell: ({ row, setEditableRowIndex, editableRowIndex }) => {
              const currentIndex = row.index;
              if (editableRowIndex !== currentIndex) {
                return (
                  <ActionButton
                    onClick={() => setEditableRowIndex(currentIndex)}
                    title="Edit"
                    icon={faEdit}
                    size="1x"
                    variant="outline-secondary"
                    className="border-300"
                  />
                );
              }
              return (
                <Flex>
                  <ActionButton
                    onClick={() => {
                      setEditableRowIndex(null);
                      if (onUpdateRow) onUpdateRow(row.values, row?.original?.id);
                    }}
                    title="Done"
                    icon={faCheck}
                    size="1x"
                    variant="outline-secondary"
                    className="ms-1 me-2"
                    color="#2c7be5"
                  />
                  <ActionButton
                    onClick={() => {
                      setEditableRowIndex(null);
                      revertData(currentIndex, true);
                    }}
                    color="#e63757"
                    title="Cancel"
                    icon={faWindowClose}
                    size="1x"
                    variant="outline-secondary"
                    className="ms-1 me-1"
                  />
                </Flex>
              );
            }
          }
        ]);
      }
    }
  );

  const _sortBy = useMemo(
    () => sortBy.map(item => `${item.id}.${item.desc ? 'desc' : 'asc'}`).toString(),
    [sortBy]
  );

  const gotoPage = page => {
    if (onChangePage) onChangePage(page);
    setPageIndex(page);
    fetchData({ sort_by: _sortBy, pageIndex: page, pageSize, globalFilter, dates, moreFilterData });
  };

  useEffect(() => {
    if (fetchData) {
      setPageIndex(0);
      fetchData({ sort_by: _sortBy, pageIndex: 0, pageSize, globalFilter, dates, moreFilterData });
    }

    if (onChangeGlobalFilter) {
      onChangeGlobalFilter({ globalFilter, dates, moreFilterData, rangeTime });
    }
  }, [_sortBy, pageSize, globalFilter, fetchData, dates, moreFilterData]);

  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            page,
            getTableProps,
            headerGroups,
            prepareRow,
            canPreviousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter,
            siblingCount,
            setHiddenColumns,
            columns,
            rowsPerPageSelection,
            visibleColumnIds,
            defaultVisibleColumnIds,
            totalCount,
            loading,
            hasDatePicker,
            onSelectDatePicker,
            activeFilter,
            setFilterStatus,
            onSubmitFilter,
            dates,
            rangeTime
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
