import { axiosClient } from './request';

const transactionServices = {
  list({
    pageSize = 20,
    pageIndex = 0,
    sort_by = '',
    globalFilter = '',
    dates = [null, null],
    moreFilterData = {}
  } = {}) {
    return axiosClient.get('/transactions', {
      params: {
        limit: pageSize,
        page: pageIndex + 1,
        search: globalFilter,
        sort_by,
        start_time: dates[0],
        end_time: dates[1],
        ...moreFilterData
      }
    });
  },
  detail(id) {
    return axiosClient.get(`/transactions/${id}`);
  },
  exportTransaction(data) {
    return axiosClient.post(`/transactions/export`, data);
  },
  listByFleet({
    pageSize = 20,
    pageIndex = 0,
    sortBy = [],
    globalFilter = '',
    dates = [null, null]
  } = {}) {
    const _sortBy = sortBy.map(item => `${item.id}.${item.desc ? 'desc' : 'asc'}`).toString();
    return axiosClient.get('/fleets', {
      params: {
        limit: pageSize,
        page: pageIndex + 1,
        search: globalFilter,
        sort_by: _sortBy,
        start_date: dates[0],
        end_date: dates[1]
      }
    });
  },
  detailByFleet(id) {
    return axiosClient.get(`/fleets/${id}`);
  },
  getStatistic(params) {
    return axiosClient.get('transactions/statistics', { params });
  },
  onTransactionRefund(id, body) {
    return axiosClient.post(`/transactions/${id}/refund`, body);
  },
  sendingReceipt(id, body) {
    return axiosClient.post(`/transactions/${id}/receipts/sending`, body);
  }
};

export default transactionServices;
